<template>
    <div class="order-details-page">
    <HeadOrders
    :TitlePage="$route.name"
    :PathPageFrom="$t('Home')"
    :PathPageCenter="$t('requested_services')"
    :PathPageTo="$t('Order_Detials')" />
    <div class="container">
    <Order_Details />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Services Order Details',
  components: {
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
    Order_Details: defineAsyncComponent( () => import('@/components/Services/Order/Order_Details.vue') ),
  },
  }
  </script>
<style scoped>
  @media (min-width: 800px) and (max-width: 992px) {
    .container {
      max-width: 845px !important;
    }
  }
</style>
